/* Colors */
$white-100: #ffffff;
$light-gray-47: #777777;
$light-gray-33: #49555e;
$dark-red: darkred;
$blue-53: #3498db;
$blue-95: #e6e6ff;

$blue-50: #0000ff;
$purple-60: #9933ff;
$green-30: #009933;
$red-50: #ff0000;

/* You can add global styles to this file, and also import other style files */
@import 'styles/_colors.scss';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

/* Horizontal Scroll Table */
div.scrollmenu {
  overflow: auto;
  white-space: nowrap;
}

div.scrollmenu a {
  display: inline-block;
  color: $blue-53;
  text-align: center;
  padding: 14px;
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
}

div.scrollmenu a:hover {
}

/* Tables */

table {
  width: 100%;
}

.mat-form-field {
  font-size: 14px;
  width: 50%;
}

td,
th {
  width: 25%;
}

td,
th {
  text-align: left !important;
  vertical-align: middle !important;
}

th.mat-header-cell {
  font-weight: bold;
}

.mat-sort-header-content {
  text-align: center;
  display: flex;
  align-items: center;
  font-weight: bold;
}

mat-header-cell,
mat-cell {
  justify-content: center;
}

.mat-sort-header-container {
  justify-content: left !important;
}

/* Cards */
mat-card {
  margin: 25px;
}

mat-card-content {
  padding: 25px;
}

/* Breadcrumb */

.breadcrumb {
  margin-top: 20px;
  margin-left: 25px;
  margin-bottom: 0;
  background-color: $white-100;
}

/* Buttons */
::ng-deep .mat-button {
  color: $blue-53;
  text-decoration: underline;
}

#refresh-container {
  display: flex;
  justify-content: right;
}

.mat-icon-color {
  color: $light-gray-33;
}

.icon-text {
  margin-top: 25px;
  display: flex;
  align-items: center;
  color: $light-gray-33;
}

#refresh-button {
  margin-right: 2em;
  background: $light-gray-33;
  color: $white-100;
}

#publish-mat-button {
  margin-right: 2em;
}

.status-button {
  background: $light-gray-33;
  color: $white-100;
}

.loading-button {
  display: block !important;
  max-width: 5em !important;
  margin: 0 auto !important;
}

.submenu-margin {
  margin-bottom: 5px;
}

.select-error {
  color: $dark-red;
  font-size: small;
}

///* width */
//::-webkit-scrollbar {
//  width: 5px;
//}
//
///* Track */
//::-webkit-scrollbar-track {
//  box-shadow: inset 0 0 5px grey;
//  border-radius: 10px;
//}
//
///* Handle */
//::-webkit-scrollbar-thumb {
//  //background: darkblue;
//  border-radius: 10px;
//}
//
///* Handle on hover */
//::-webkit-scrollbar-thumb:hover {
//  background: grey;
//}

.device-list .mat-dialog-container {
  overflow-y: hidden !important;
}

.current-config .mat-dialog-container {
  overflow-y: hidden !important;
}
.view-config .mat-dialog-container {
  overflow-y: hidden !important;
}
.device-settings .mat-dialog-container {
  overflow-y: hidden !important;
}

// Links
a:link {
  color: $blue-53;
  background-color: transparent;
  text-decoration: none;
}
a:visited {
  color: $purple-60;
  background-color: transparent;
  text-decoration: none;
}
a:hover {
  color: $red-50;
  background-color: transparent;
  text-decoration: underline;
}
a:active {
  color: $green-30;
  background-color: transparent;
  text-decoration: underline;
}

.mat-menu-content {
  background-color: #b3b3b3 !important;
}

.mat-menu-item {
  color: white !important;
}
.mat-menu-item:hover {
  color: #004a84 !important;
  background-color: white !important;
  text-decoration: none;
}

.rotate {
  animation: rotation 2s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.export-button {
  background: #49555e;
  color: white;
}
